import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import '../img/icons/fontawesome'

import Layout from '../components/Layout'
import { Container, Row, Col, Heading, RouterButton, Text, Image } from '../components/UI'
import AarhusAirport from '../img/logo_aarhus_airport.png'
import Langeland from '../img/logo_langeland.png'
import MitAps from '../img/logo_mit-aps.png'
import Evionica from '../img/logo_evionica.png'
import Logo from '../img/logo.svg'
import ServiceOverviewBlurbs from '../components/ServiceOverviewBlurbs';

export const IndexPageTemplate = ({
  hero,
  workedwith,
  serviceoverview,
  aboutoverview
}) => (
  <>
    {/* Hero */}
    <Container maxWidth="content" mx="auto">
      <Container mt={["0", "xxxl"]}>
        <Row>
          <Col md={5} p="xl">
            <Container mt={["0", "0", "0", "xxxxl"]}>
              <Heading highlight>{hero.title}</Heading>
              <Text fontSize="xl">{hero.subtitle}</Text>
              <RouterButton to="/services" m="0" p="0" px="lg" py="md">Læs mere</RouterButton>
            </Container>
          </Col>
          <Col md={7} p="xl">
            <Container>
              <Image src={!!hero.heroimage.image.childImageSharp ? hero.heroimage.image.childImageSharp.fluid.src : hero.heroimage.image} alt={hero.heroimage.alt} width="100%" height="auto" />
              <Container opacity=".9">
                <Image src={Logo} width="10%" height="auto" position="absolute" alt="SecSafeConsult logo" right="-1.25vw" bottom="-1.25vh" />
              </Container>
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>

    {/* Workedwith */}
    <Container textAlign="center" mt="subsection">
      <Text fontSize="xl" px={["xl", "0"]}>{workedwith}</Text>
      <Container mt="lg">
        <Image src={MitAps} alt="MitAps logo" height="40px" mx="xl" />
        <Image src={Evionica} alt="Evionica logo" height="40px" mx="xl" />
        <Image src={AarhusAirport} alt="Aarhus Airport logo" height="40px" mx="xl" />
        <Image src={Langeland} alt="Langelandsfestivalens logo" height="40px" mx="xl" />
      </Container>
    </Container>

    {/* ServiceOverview */}
    <Container textAlign="center" mt="section" backgroundColor="bg.1">
      <Container py="subsection">
        <Heading type="h2" px={["xl", "0"]}>{serviceoverview.heading}</Heading>
        <Text fontSize="lg" px={["xl", "0"]}>{serviceoverview.subheading}</Text>

        <ServiceOverviewBlurbs blurbs={serviceoverview.blurbs} />
        <RouterButton to="/services" m="0" mt="xxxl" p="0" px="lg" py="md">Se mine services</RouterButton>
      </Container>
    </Container>

    {/* AboutOverview */}
    <Container my="subsection">
      <Container maxWidth="content" mx="auto">
        <Row>
          <Col md={6} p="0" px={["xxxl", "xxxl", "xxxl", "subsection"]} textAlign="center">
            <Image src={!!aboutoverview.profileimage.image.childImageSharp ? aboutoverview.profileimage.image.childImageSharp.fluid.src : aboutoverview.profileimage.image} alt={aboutoverview.profileimage.alt} borderRadius="circle" height="auto" width="100%" />
            <Text textAlign="center" mt="lg" fontStyle="italic">{aboutoverview.imagetext}</Text>
          </Col>
          <Col md={6} p="0" px={["xl", "xxxxl"]}>
            <Heading type="h2" highlight>{aboutoverview.heading}</Heading>
            <Text>{aboutoverview.text}</Text>
            <RouterButton to="/about" m="0" p="0" px="lg" py="md">Om mig</RouterButton>
          </Col>
        </Row>
      </Container>
    </Container>
  </>
)

IndexPageTemplate.propTypes = {
  hero: PropTypes.object,
  workedwith: PropTypes.string,
  serviceoverview: PropTypes.object,
  aboutoverview: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        hero={frontmatter.hero}
        workedwith={frontmatter.workedwith}
        serviceoverview={frontmatter.serviceoverview}
        aboutoverview={frontmatter.aboutoverview}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        hero {
          title
          subtitle
          heroimage {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        workedwith
        serviceoverview {
          heading
          subheading
          blurbs {
            icon
            text
          }
        }
        aboutoverview {
          profileimage {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          imagetext
          heading
          text
        }
      }
    }
  }
`
