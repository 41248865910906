import React from 'react';
import { Container, Row, Col, Icon, Text } from './UI';

function ServiceOverviewBlurbs(props) {
  const { blurbs } = props;

  function getColor(index) {
    const x = index % 4;

    switch (x) {
      case 0:
      default:
        return "#3545EE";

      case 1:
        return "#FF6700";

      case 2:
        return "#32CD32";

      case 3:
        return "#FFD018";
    }
  }

  return (
    <Container mt="xxxxl" maxWidth="content" mx="auto">
      <Row>
        {blurbs && blurbs.map((item, index) => (
          <Col sm={12 / blurbs.length} key={index}>
            <Icon icon={item.icon} size="4x" color={getColor(index)} />
            <Text mt="lg">{item.text}</Text>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ServiceOverviewBlurbs;